import React from 'react'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import Article from '../../components/Article'
import IconTeaser from '../../components/IconTeaser'
import NavigationLink from '../../components/NavigationLink'
import IndividualContactSection from '../../components/IndividualContactSection'
import Card from '../../components/Card'
import CardDeck from '../../components/CardDeck'

const Page = () => {
  return (
    <React.Fragment>
      <Seo title="Referenz: ThyssenKrupp Steel Europe"
           description="Erfahren Sie, wie wir die ThyssenKrupp Steel Europe AG bei der Erstellung eines neuen, einheitlichen Fertigungsleitsystems unterstützt haben."
      />

      <BigTitle
        title="Kosteneinsparung dank einheitlichem Fertigungsleitsystem"
        imageRelativePath={'Titelbild_EnterpriseJava.jpg'}
        backgroundColor="#fff"
        className="mask-black"
        textInverted={true}
        menuInverted={true}
        logoInverted={true}
      >
        Case Study - ThyssenKrupp Steel Europe
      </BigTitle>
      <DefaultSection className="pt-5 pt-lg-0">
        <Article>
          <h1>Das Projekt</h1>
          <p>
            Die ThyssenKrupp Steel Europe AG produziert an verschiedenen
            Standorten innerhalb Deutschlands und Europas hochwertige Flachprodukte aus Qualitätsstahl. Die Fertigung
            ist
            hochautomatisiert und erfolgt teilweise standortübergreifend. Mit diesem Projekt sollen die bestehenden
            Fertigungsleitsysteme vereinheitlicht und durch ein Inhouse-entwickeltes, flexibel anpassbares Manufacturing
            Excecution System ersetzt werden. Auf Basis der Java-Enterprise-Technologien soll ein System geschaffen
            werden, das auch zukünftigen Anforderungen gewachsen ist. Unsere Aufgabe bestand darin, eine tragfähige
            Persistenzstrategie zu entwickeln und zu implementieren.
          </p>
        </Article>
        <CardDeck className="mt-5 mb-3" cardsPerRow={2}>
          <Card>
            <div className="text-center text-md-left">
              <h2>Technologien</h2>
              <p className="mt-5">
                Oracle WebLogic Server, EclipseLink, openArchitectureWare
              </p>
            </div>
          </Card>
          <Card>
            <div className="text-center text-md-left">
              <h2>Branche</h2>
              <p className="mt-5">
                Stahlindustrie
              </p>
            </div>
          </Card>
        </CardDeck>
        <Article>
          <blockquote className="text-brand">
            <p>
              Auf der Basis moderner Enterprise-Java-Technologien ist es uns gelungen, ein performantes, hochverfügbares
              und flexibles Fertigungsleitsystem zu erstellen, welches den heutigen und zukünftigen Ansprüchen für die
              Auftrags- und Materialversorgung der verschiedenen Aggregate an den Standorten gewachsen ist. <br /><em
              className="text-muted"><small> Joachim Kaminski, Projektleiter, ThyssenKrupp Steel
              Europe, Duisburg</small></em>
            </p>
          </blockquote>
          <p>
            Stahl ist der mit Abstand wichtigste industrielle Werkstoff. Die ThyssenKrupp Steel Europe AG innerhalb des
            ThyssenKrupp Konzerns konzentriert sich auf die Herstellung und den Vertrieb hochwertiger Flachprodukte aus
            Qualitätsstahl sowie die Erbringung von produktbezogenen Dienstleistungen. Die ThyssenKrupp Steel Europe AG
            gehört zu den weltweit technologisch führenden Qualitätsflachstahl-Anbietern. Das Unternehmen übernimmt eine
            Schrittmacherrolle bei der Weiterentwicklung von High Tech-Stählen, innovativen Oberflächen und Verarbeitung
            anwendungstechnischer Problemlösungen für die Kunden.
          </p>
          <p>
            Die Herstellung innovativer Produkte bedarf einer präzisen Steuerung des Fertigungsprozesses. Eine zentrale
            Komponente ist dabei das Fertigungsleitsystem. Es dient der Versorgung der einzelnen Produktionsaggregate
            eines Standorts mit Material- und Auftragsdaten. Es beinhaltet ebenfalls einen Teil zur Steuerung des
            Materialflusses zwischen den Anlagen, zu anderen Standorten und zum Kunden.

          </p>
        </Article>
      </DefaultSection>

      <DefaultSection className="bg-level-1">
        <IconTeaser imageRelativePath={'Thyssenkrupp_AG_Logo_2015.png'} iconImageAlt="Logo ThyssenKruppSteel AG">
          <p className="text-black-50">
            Die <NavigationLink to="https://www.thyssenkrupp-steel.com/de"
                                ariaLabel="Link zur Webseite der Thyssen Krupp Steel AG" asTextLink="true">ThyssenKrupp
            Steel Europe AG</NavigationLink> ist eine 100 Prozent Tochter des ThyssenKrupp Konzerns. Das Unternehmen
            konzentriert sich auf das attraktive Marktsegment des hochwertigen Qualitätsflachstahls und gehört dort zu
            den weltweit führenden Produzenten. Das Leistungsspektrum reicht von intelligenten Werkstofflösungen über
            produktspezifische Anarbeitung, Dienstleistungen und umfassenden Service
          </p>
        </IconTeaser>
      </DefaultSection>

      <DefaultSection>
        <Article>
          <h2>Ablösung der bestehenden Fertigungsleitsysteme</h2>
          <p>
            Um den steigenden Betriebs- und Wartungskosten der bestehenden Fertigungsleitsysteme Einhalt zu gebieten,
            entschloss sich die ThyssenKrupp Steel Europe AG, ein einheitliches und modernes Fertigungsleitsystem für
            die deutschen Betriebsstätten einzuführen.
          </p>
          <p>
            Um das eigene Wissen über die Fertigungsprozesse besser in die Anwendung integrieren zu können, erfolgte die
            Realisierung des neuen Anwendungssystems vollständig intern unter zeitweiser Nutzung externen Knowhows zu
            spezifischen Java-Technologien.
          </p>
          <p>
            Folgende Herausforderungen mussten durch das Projekt-Team gemeistert werden: Vor und während des
            Fertigungsprozesses fallen große Datenmengen an. Diese ergeben sich einerseits aus den individuellen
            Auftragsdaten und andererseits aus den Prozessdaten, die während der Verarbeitung anfallen. Ebenfalls müssen
            alle Messdaten im Rahmen der Qualitätskontrolle eindeutig Aufträgen und Materialstücken zugeordnet werden
            können. Materialstücke weisen somit eine Vielzahl unterschiedlicher Eigenschaften auf, die von den
            Fachbereichen im Blick behalten werden müssen. Nicht alle Daten werden allerdings von jedem
            Prozessbeteiligten benötigt. Das neue Fertigungsleitsystem ermöglicht deshalb auf komfortable Weise, sich
            eigene Übersichtstabellen zu definieren und diese auch in andere Anwendungen zu exportieren. Auch stehen
            intuitiv zu bedienende Abfragefilter bereit, mit deren Hilfe gezielt Informationen über einzelne
            Materialstücke oder einzelne Aufträge ermittelt werden können.
          </p>
          <h2>Die Prozessqualität steht im Vordergrund</h2>
          <p>
            Eine weitere Herausforderung an das neue Fertigungsleitsystem war die Vermeidung von Fehleingaben. Das neue
            System steigert die Prozessqualität dadurch, indem es schon im Vorfeld umfangreiche Plausibilisierungen
            vornimmt und dadurch frühzeitig unzulässige Eingaben der Nutzer verhindert. Der Nutzer weiß durch
            kontextsensitive Hilfetexte und aussagekräftige Fehlermeldungen jederzeit, warum eine Eingabe aktuell nicht
            zulässig ist.
          </p>
          <p>
            Die Benutzeroberfläche wurde auf Basis der Eclipse Rich-Client-Platform entwickelt. Neben den
            Abfragemöglichkeiten zu Materialstücken, Aufträgen und Stammdaten sind auch alle Steuerungsfunktionen – wie
            z. B. Verpacken, Verladen oder die Korrektur der Ist-Daten – über dieselbe, einheitliche Oberfläche
            erreichbar. Die Benutzeroberfläche ist dabei modular aufgebaut und kann später nahtlos um weitere
            Funktionalität ergänzt werden.
          </p>
          <p>
            Das neue Fertigungsleitsystem arbeitet im Kern ereignisgesteuert: Dialogeingaben lösen fachliche Ereignisse
            aus, die in den aktuellen Datenbestand eingebucht werden. Nachrichten aus angrenzenden Systemen werden in
            gleicher Weise behandelt. Damit ist gewährleistet, dass Zustandsänderungen in Fremdsystemen mit sehr
            geringer Verzögerung und hoher Konsistenz im Fertigungsleitsystem reflektiert werden. Die Synchronisierung
            findet bidirektional statt und nutzt unternehmensweit gültige XML-Datenstrukturen. Zukünftige Systeme können
            daher ohne großen Aufwand ebenfalls angebunden werden.

          </p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <Article>
          <h2>
            Warum die BUSCHMAIS GbR als Partner?

          </h2>
          <p>
            Die BUSCHMAIS GbR ist ein deutschlandweit tätiges Beratungsunternehmen mit Fokus auf Technologie- und
            Architekturberatung. Sie ist ein verlässlicher, unabhängiger Partner mit umfassendem Knowhow im Bereich der
            Java-Enterprise-Technologien. Die BUSCHMAIS GbR entwarf und implementierte die Persistenz-Strategie unter
            Verwendung von
            EclipseLink und openArchitectureware.
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <blockquote className="text-brand">
            <p>
              Die Mitarbeiter von BUSCHMAIS haben dank ihrer Kompetenzen beim Einsatz von EclipseLink die effiziente
              Anbindung der Datenbank an das System sichergestellt. Sie haben damit einen wichtigen Beitrag zum
              Projekterfolg geleistet.<br /><em className="text-muted"><small>Matthias Bross, Projektleiter der
              Migration, ThyssenKrupp Steel Europe, Duisburg</small></em>
            </p>
          </blockquote>
          <br />
          <h2>Erfolgreiche Inbetriebnahme an der ersten Anlage in Rekordzeit</h2>
          <p>
            Für die reibungslose Einführung des neuen Fertigungsleitsystems waren umfangreiche Koordinierungsarbeiten
            notwendig. Das neue System wurde innerhalb kürzester Zeit in die bestehende, heterogene Systemlandschaft
            eingebettet und konnte nach zwei Wochen Parallel- und
            Probebetrieb bereits das alte System ablösen. Im Vorfeld war eine sorgfältige Übernahme der Altdaten
            erforderlich. Um den reibungslosen Betrieb des Fertigungsleitsystems zu gewährleisten, weist das neue System
            vielfältige Möglichkeiten der System-Überwachung auf. Auf der Basis standardisierter Schnittstellen kann so
            das neue System in bestehende Monitoring-Lösungen des Rechenzentrumsbetriebs integriert werden.
            Fehlerzustände können damit schnell erkannt und frühzeitig behoben werden.

          </p>
          <p>
            Der Entwicklungsprozess für das neue Fertigungsleitsystem verfolgte einen modellgetriebenen Ansatz. So wurde
            das Datenmodell graphisch auf Basis der UML entworfen und konnte damit zugleich in verschiedene
            Programm-Artefakte transformiert werden. Neben einer höheren Code-Qualität konnte man so über lange
            Zeiträume auch eine hohe
            Flexibilität hinsichtlich der Anwendungsarchitektur gewährleisten.
            Nicht nur das Datenmodell wurde graphisch modelliert – auch die
            Prozessbeschreibungen und das Deployment-Konzept liegen als UML-Modelle vor.

          </p>
          <h2>Die Vorteile des neuen Systems</h2>
          <p>
            <ul>
              <li>Optimierung der Prozesse und Kostensenkung dank Konsolidierung der Fertigungsleitsysteme aller
                deutschen Standorte
              </li>
              <li>Aufbau einer skalierbaren, flexiblen, hochverfügbaren Umgebung, die auch zukünftigen Anforderungen
                gewachsen ist
              </li>
              <li>Reduzierung der Hardware-, Betriebs- und Wartungskosten</li>
              <li>Ergonomische Benutzeroberflächen mit umfangreichen Abfragemöglichkeiten</li>
              <li>Vereinfachte und schnellere Nachverfolgbarkeit des gesamten Herstellungsprozesses</li>
              <li>Hohe Flexibilität der Materialflusssteuerung</li>
              <li>Frühzeitige Fehlererkennung und Fehlervermeidung</li>
            </ul>
          </p>
        </Article>
      </DefaultSection>
      <DefaultSection className="bg-level-1">
        <Article>

          <h2>Warum EclipseLink?</h2>
          <p>
            ThyssenKrupp Steel Europe stellt sehr hohe Ansprüche an Verlässlichkeit, Skalierbarkeit und Effizienz der
            eingesetzten Software. Das neue Fertigungsleitsystem ist für den 24/7-Betrieb ausgelegt, die eingesetzte
            Hardware muss ökonomisch ausgelastet werden und das System muss eine kontinuierlich hohe Datenlast
            bewältigen.

          </p>
          <p>Als O/R-Mapping-Technologie zwischen Datenbank und Programm-Logik kam EclipseLink zum Einsatz. Die Wahl
            fiel auf EclispeLink nicht nur wegen seiner hohen Erwartungskonformität. Das Framework brachte entscheidende
            Vorteile mit sich:
          </p>
          <ul>
            <li>Standardkonformität zu JPA,</li>
            <li>Mächtigkeit der Abfragesprache,</li>
            <li>Erweiterbarkeit der O/R-Mappings und</li>
            <li>situativ anpassbare Ladestrategien</li>
          </ul>
          <p>Während der Implementierung stand dem Projekt das EclipseLink- Committer-Team mit Hinweisen und Beispielen
            tatkräftig zur Seite.</p>
        </Article>
      </DefaultSection>
      <DefaultSection>
        <Article>
          <p>
            <NavigationLink to="/download/Case-Study_TKS_2011.pdf" asTextLink
                            ariaLabel="Case Study Kosteneinsparung dank einheitlichem Fertigungsleitsystem bei ThyssenKrupp Steel Europe">Download
              (PDF)</NavigationLink>
            <br /><small className="text-muted">Stand: 2011</small>
          </p>
        </Article>
      </DefaultSection>
      <IndividualContactSection personKey={'frank'} />
    </React.Fragment>
  )
}

export default Page
